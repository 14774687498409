<template>
    <div>
        <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
        <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" data-cy="form-matched"/>
        <matched-approval-form :is-show="isShowSettleDetails" :details="details" :merchantCodes="listOfMerchantCodes" v-on:close-dialog="closeDialog" v-on:for-approval-status="updateBatchStatusForApproval(details.item)" data-cy="form-matched-approval"/>
        <v-container class="container--fluid grid-list-md text-center">
            <h1>Upload Merchant CSV</h1>
        </v-container>

        <v-card elevation="2" outlined shaped tile padding="20px">
            <v-row class="ma-1">
                <v-col cols="12" md="5">
                    <v-menu v-model="fromDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-from">
                        <template v-slot:activator="{ on }">
                            <v-text-field label="From" prepend-icon="mdi-calendar" readonly :value="fromDateDisp" v-on="on" data-cy="date-menu-from"></v-text-field>
                        </template>
                        <v-date-picker locale="en-in" v-model="fromDateVal" no-title @input="fromDateMenu = false" :min="minDate" :max="fromMax" data-cy="date-menu-from"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" md="5">
                    <v-menu ref="menu" v-model="toDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-to">
                        <template v-slot:activator="{ on }">
                            <v-text-field label="To" prepend-icon="mdi-calendar" readonly :value="toDateDisp" v-on="on" data-cy="date-menu-to"></v-text-field>
                        </template>
                        <v-date-picker locale="en-in" v-model="toDateVal" no-title @input="toDateMenu = false" :max="maxDate" :min="toMin" data-cy="date-menu-to">
                            <v-btn text color="primary" @click="clearToDate()">Clear
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>  

            <v-row class="ma-1 pl-3">
                <v-col class="d-flex" cols="12" md="3">
                    <v-autocomplete
                        class="mb-5 pl-2"
                        clearable chips
                        :items="merchantCodes" 
                        label="Merchant" 
                        v-model="filteredTransaction.merchantCode" 
                        return-object
                        data-cy="merchant-code-list"
                        @change="searchMerchantCode">
                    </v-autocomplete>
                </v-col>
                <v-col class="d-flex" cols="12" md="2">
                    <v-text-field append-icon="mdi-magnify" class="px-4" label="File Name" single-line @change="searchOriginalFilename" data-cy="payconnect-reference-text">
                    </v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" md="2">
                    <v-text-field append-icon="mdi-magnify" class="px-4" label="File upload ID" single-line @change="searchFileUploadId" data-cy="merchant-reference-text">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="text-left">
                    <v-btn class="ml-5" dark medium color=success @click="queryData(true)" data-cy="action-button-search">
                        <v-icon dark left> mdi-magnify</v-icon>Search
                    </v-btn>  
                </v-col>
                <v-col class="text-left">
                    <v-dialog ref="dialog" v-model="dialogMenu" class="text-center" persistent width="500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mr-5" color=success @click="openUploadFileDialog()" data-cy="action-button-for-approval" dark v-bind="attrs" v-on="on">
                                Upload merchant csv
                            </v-btn>
                        </template>
                            <v-card elevation="2" shaped tile>
                                <v-card-title class="text-h5 grey lighten-2">
                                    Upload CSV
                                </v-card-title> 
                                <v-form ref="formEntry">
                                    <v-row class="ma-5">    
                                        <v-col class="d-flex" cols="12" md="5">
                                            <v-flex xs12>
                                                <v-menu :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-from"> 
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field label="Business Date" prepend-icon="mdi-calendar" readonly :value="displayBusinessDate" v-on="on" required :rules="[rules.requiredField]"></v-text-field>
                                                    </template>
                                                    <v-date-picker locale="en-in" v-model="businessDateVal" no-title :min="minBusinessDate" :max="maxBusinessDate">
                                                    </v-date-picker>
                                                </v-menu>   
                                            </v-flex>
                                        </v-col>
                                        <v-col class="d-flex" cols="12" md="5">
                                            <v-flex xs12>    
                                                <v-autocomplete
                                                    class="mb-5 pl-2"
                                                    :items="merchantCodes" 
                                                    label="Merchant" 
                                                    v-model="dialogFiltered.merchantCode" 
                                                    return-object
                                                    data-cy="merchant-code-list"
                                                    @change="dialogSearchMerchCode"
                                                    required :rules="[rules.requiredField]">
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-col>
                                    </v-row> 
                                </v-form> 
                            <v-spacer></v-spacer>
                                <v-container class="center large-12 medium-12 small-12 cell"> 
                                    <v-row class="ma-5">    
                                        <v-col class="d-flex">
                                            <v-flex xs12>  
                                                <h3>File: 
                                                    <input type="file" id="file" ref="file" v-on:change="handleFileUpload"/>
                                                </h3>
                                            </v-flex>  
                                        </v-col>
                                    </v-row>
                                </v-container>
                            <v-spacer></v-spacer>
                        <v-divider></v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="dialogMenu = false">Cancel</v-btn>
                                <v-btn text color="primary" :disabled="noFiles" @click="submitFile()">Submit</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row> 
        </v-card>

        <!-- <div class="text-uppercase text-bold">id selected: {{selected}}</div> -->
        <!-- <div class="col-md-6 text-right">
            <strong>Total Amount:</strong> <span class="price-content">{{totalAmount}}</span>
        </div> -->

        <template>
            <v-card>
                <v-data-table :hide-default-header="true" :headers="headers" :items="displayMatchedTransactions" :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options" :server-items-length="totalMatchedTransactions" :footer-props="{'items-per-page-options': [10]}">
                    <template v-slot:header="{ props: { headers } }">
                            <thead>
                                <tr :colspan="headers.length">
                                    <th v-for="header in headers" :key="header.text">
                                        <span class="d-flex justify-center">{{header.text}}</span>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:body="{ items }">
                            <tbody>
                                <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                                    <td> <span class="d-flex justify-center"> {{ item.id }} </span></td>
                                    <td> <span class="d-flex justify-center"> {{ item.merchantCode }} </span></td>
                                    <td> <span class="d-flex justify-center"> {{ item.businessDate }} </span></td>
                                    <td> <span class="d-flex justify-center"> {{ item.originalFilename }} </span></td>
                                    <td> <span class="d-flex justify-center"> {{ item.paymentAgentCode }} </span></td>
                                    <td> <span class="d-flex justify-center"> {{ item.uploadDate }} </span></td>
                                    <td> <span class="d-flex justify-center"> {{ item.uploadedBy }} </span></td>
                                    <td> <span class="d-flex justify-center"> {{ item.status }} </span></td>
                                    <td>
                                        <span class="d-flex justify-center">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-btn fab dark x-small v-bind="attrs" v-on="on" color="green" class="mr-2" @click.stop="viewTransaction(item)">
                                                    <v-icon dark medium>mdi-magnify-plus</v-icon>
                                                </v-btn>
                                                </template>
                                                <span>View details</span> 
                                            </v-tooltip>   
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-btn fab dark x-small v-bind="attrs" v-on="on" color="green" class="mr-2" @click.stop="download(item)" data-cy="list-action-button-csv">
                                                    <v-icon dark medium>mdi-cloud-download</v-icon>
                                                </v-btn>
                                                </template>
                                                <span>Download CSV</span> 
                                            </v-tooltip> 
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                        <template v-slot:no-results>
                            <v-alert :value="true" color="error" icon="warning">
                                Found no results.
                            </v-alert>
                        </template>
                </v-data-table>           
            </v-card>
        </template>
    </div>
</template>

<script>
import {
    mapGetters
} from 'vuex';
import API from '@/api/API'
import Loading from '@/views/components/Loading.vue'
import DetailsForm from '@/views/components/DetailsForm.vue'
import MatchedApprovalForm from '@/views/components/matchedApprovalForm.vue'
import moment from 'moment';

export default {
    name: 'Unmatched',
    components: {
        Loading,
        DetailsForm,
        MatchedApprovalForm
    },
    computed: {
        ...mapGetters([
            'attributes',
            'authorities'
        ]),
        fromDateDisp() {
            this.toMin = moment().format(this.fromDateVal)
            return this.fromDateVal;
        },
        toDateDisp() {
            this.fromMax = moment().format(this.toDateVal)
            return this.toDateVal;
        },
        displayBusinessDate() {
            return this.businessDateVal
        },
    },
    data() {
        return {
            rules: {
               requiredField: (v) => !!v || "Required field.",
            },
            headers: [
                {
                    text: 'ID',
                    value: 'id'
                },
                {
                    text: 'Merchant',
                    value: 'merchantCode'
                },
                {
                    text: 'Business Date',
                    value: 'businessDate'
                },
                {
                    text: 'File name',
                    value: 'originalFilename'
                },
                {
                    text: 'Payment Agent Code',
                    value: 'paymentAgentCode'
                },
                {
                    text: 'Upload Date',
                    value: 'uploadDate'
                },
                {
                    text: 'Uploaded by',
                    value: 'uploadedBy'
                },
                {
                    text: 'Status',
                    value: 'status'
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    sortable: false
                },
            ],
            filteredTransaction: {},
            dialogFiltered: {},
            listOfMerchantCodes: [],
            merchantCodes: this.listOfMerchantCodes ?? [],
            loading: true,
            page: 1,
            limit: '$vuetify.dataFooter.itemsPerPageText',
            totalMatchedTransactions: 0,
            numberOfPages: 0,
            options: {
                rowsPerPage: 10
            },
            details: {
                title: '',
                item: {}
            },
            fromDateMenu: false,
            fromDateVal: "",
            toDateMenu: false,
            toDateVal: "",
            minDate: "1997-01-01",
            maxDate: "",
            fromMax: "",
            toMin: "",
            offset: 0,
            limit: 0,
            displayMatchedTransactions: [],
            remainderMatchedTransactions: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            isShowDetails: false,
            isShowSettleDetails: false,
            showFormDialog: false,
            isHtml: false,
            selected: [],
	    	selectAll: false,
            totalCount:'',
            businessDateMenu: false,
            dialogMenu: false,
            businessDateVal: undefined,
            minBusinessDate: "1997-01-01",
            maxBusinessDate: undefined,
            file: '',
            noFiles: true,
        }
    },
    watch: {
        options: {
            handler() {
                this.queryData(false);
                this.setData();
            },
        },
        listOfMerchantCodes: {
            handler(value) {
                this.merchantCodes = value
            }
        },
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        async setData() {
            try {
                const listResponse = await API.getMerchantCode()
                if (!listResponse && listResponse.error) {
                    console.log(`${listResponse.error}`)
                } else {
                    this.listOfMerchantCodes = listResponse.merchantCodes
                }
            } catch (e) {
                console.log(e)
            }
        },
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                var matchedTransactionList = undefined
                
                const parameters = {
                    merchantCode: this.filteredTransaction ? this.filteredTransaction.merchantCode ?? '' : '',
                    filename: this.filteredTransaction.originalFilename,
                    fileUploadHistoryId: this.filteredTransaction.id,
                    dateFrom: this.fromDateVal, 
                    dateTo: this.toDateVal,
                    pageNo: pageNumber,
                    pageSize: itemsPerPage
                }

                matchedTransactionList = await API.getUploadedFileList(parameters)
                if (!matchedTransactionList || matchedTransactionList.error) {
                    console.log(`${matchedTransactionList.error}`)
                } else {
                    var filteredList = matchedTransactionList.merchantFileUploadHistory
                    var length = matchedTransactionList.rowCount
                    if (page > 1 && this.remainderMatchedTransactions.length > 0) {
                        filteredList = this.remainderMatchedTransactions.concat(filteredList)
                    }
                    // this.totalMatchedTransactions = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                    this.totalMatchedTransactions = length
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayMatchedTransactions = filteredList.slice(0, end)
                    this.remainderMatchedTransactions = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = this.totalMatchedTransactions / itemsPerPage

                    parameters.offset = this.totalMatchedTransactions
                    parameters.limit = 1
                    
                    const hasMoreResponse = matchedTransactionList
                    if (!hasMoreResponse || hasMoreResponse.error) {
                        console.log(`${hasMoreResponse.error}`)
                    } else {
                        var hasMoreList = hasMoreResponse.merchantFileUploadHistory
                        if(hasMoreList.length > 0) {
                        // this.totalMatchedTransactions = Math.floor(this.totalMatchedTransactions/10) * 10 + 1
                        this.numberOfPages = this.numberOfPages + 1
                        }
                    }
                }
            } catch (e) {
                console.log(e)
            }
            this.loading = false
        },
        handleFileUpload(event) {
            this.file = this.$refs.file.files[0];
            this.noFiles = !event.target.files.length;
        },
        async submitFile(){
            if (this.$refs.formEntry.validate()) {
                this.loadingDialog = true
                this.isHtml = true
                this.loadingFinished = false
                try {
                    this.loadingMessage = `Uploading File <strong><i>${this.file.name}</i></strong>`
                    let formData = new FormData();
                    // file
                    // merchantId

                    //--new--//
                    // expirationDate
                    // transactionDescription
                    // receiptRemarks
                    formData.append('file', this.file);
                    formData.append('merchantCode', this.dialogFiltered.merchantCode)
                    formData.append('businessDate', this.businessDateVal)
                    const response = await API.uploadFileMerchant(formData)
                    this.loadingFinished = true
                    if (!response || response.error) {
                        //error getting data
                        console.log(`${response.error}`)
                        this.isHtml = false
                        this.loadingMessage = response.error
                    } else {
                        this.loadingMessage = `Successfully uploaded <strong><i>${this.file.name}</i></strong>`
                        console.log("Upload Successfull")
                    }
                } catch (e) {
                    console.log(e)
                    this.isHtml = false
                    this.loadingMessage = e.error
                }
                 this.loading = false
            }
        },
        searchMerchantCode: async function(val) {
            this.filteredTransaction.merchantCode = val
            this.dialogFiltered.merchantCode = val
            // this.queryData(true)
        },
        dialogSearchMerchCode: async function(val) {
            this.dialogFiltered.merchantCode = val
            // this.queryData(true) 
        },
        searchOriginalFilename(val) {
            this.filteredTransaction.originalFilename = val
            // this.queryData(true)
        },
        searchFileUploadId(val) {
            this.filteredTransaction.id = val
            // this.queryData(true)
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
                this.isShowSettleDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.queryData();
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        async download(transaction) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            this.loadingMessage = `Downloading CSV please wait...`
            const downloadResponse = await API.exportMerchantFileUpload(transaction)
            this.loadingFinished = true
            if (!downloadResponse || downloadResponse.error) {
                this.isHtml = false
                this.loadingMessage = downloadResponse ? downloadResponse.error : "Error downloading file."
            } else {
                this.loadingMessage = `Download complete.`
            }
        },
        viewTransaction(transaction) {
            console.log(transaction)
            this.details.item = transaction
            this.isShowDetails = true
        },
        openUploadFileDialog() {
            this.businessDateMenu = true
        },
        updateBatchStatusForApproval(value) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
                this.loadingMessage = `Creating settlement batch please wait...`
                setTimeout(async () => {
                    const createResponse = await API.updateSettlementBatchForApproval(value)
                    console.log(createResponse)
                    this.loadingFinished = true
                    if (!createResponse || createResponse.error) {
                        this.isHtml = false
                        this.loadingMessage = createResponse.error
                    } else {
                        this.loadingMessage = `Successfully updated settlement batch!`
                        this.showFormDialog = false
                        // this.$refs.userForm.resetForm()
                    }
                }, 2000);
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
    }
};
</script>
