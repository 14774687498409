<template>
<v-container row justify-center>
    <v-dialog max-width="600px" persistent v-model="isShowDetails">
        <v-card ref="formCard">
            <v-card-title class="text-h5 grey lighten-2">
                Create Settlement Batch 
            </v-card-title>
            <v-card-text>
                <v-form ref="formEntry">
                    <v-row>
                        <v-col cols="12">
                            <v-flex xs12>
                                <v-menu v-model="fromDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="form-date-menu-from">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field label="From*" v-model="details.selectedFromDate" prepend-icon="mdi-calendar" readonly :value="fromDateDisp" v-on="on" required :rules="[rules.requiredField]" data-cy="form-from-date"></v-text-field>
                                    </template>
                                </v-menu>
                            </v-flex>
                        </v-col>
                        <v-col cols="12">
                            <v-menu ref="menu" v-model="toDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="form-date-menu-to">
                                <template v-slot:activator="{ on }">
                                    <v-text-field label="To*" v-model="details.selectedToDate" prepend-icon="mdi-calendar" readonly :value="toDateDisp" v-on="on" required :rules="[rules.requiredField]" data-cy="form-date-to"></v-text-field>
                                </template>
                            </v-menu>
                        </v-col>
                        <v-col class="d-flex" cols="12">
                            <v-autocomplete
                                prepend-icon="mdi-card-search"
                                :items="merchantCodes" 
                                label="Merchant*" 
                                v-model="details.selectedMerchant" 
                                required :rules="[rules.requiredField]"
                                return-object
                                readonly
                                data-cy="form-merchant-code-list"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex justify-center">
                        <v-col class="d-flex justify-center" cols="12" md="5">  
                            <h2 style="color:black;" class="ma-1">Total Count: </h2>
                            <h2 class="mt-1">{{details.totalSelectedCount}}</h2> 
                        </v-col> 
                        <v-col class="d-flex justify-center" cols="12" md="6">    
                            <h2 style="color:black;" class="ma-1" >Total Amount: </h2>
                            <h2 class="mt-1">₱{{commarized(details.totalSelectedAmount)}}</h2> 
                        </v-col>
                    </v-row>  
                </v-form> 
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="forApprovalStatus" data-cy="form-button-approve">
                    CREATE
                </v-btn>
                <v-btn color="green darken-1" text @click="close" data-cy="form-button-close">
                    CLOSE
                </v-btn>
                <!-- <v-btn color="green darken-1" text @click="sendReversal" v-if="isValidReverseStatus('REVERSED')">
                    Send Reversal
                </v-btn> -->
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import moment from 'moment';

export default {
    props: {
        isShow: Boolean,
        formType: String,
        loadingDialog: Boolean,
        details: Object,
        merchantCodes: Array,
    },
    watch: {
        merchantCodes: {
            handler(value) {
                this.listOfMerchantCodes = value ? value : [];
            },
        },
        isShow: {
            handler(value) {
                this.isShowDetails = value
            }
        },
        deep: true,
    },
    computed: {
        populatedForm() {
            this.details.item.merchantCode = this.details.selectedMerchant
            this.details.item.dateFrom = this.details.selectedFromDate
            this.details.item.dateTo = this.details.selectedToDate
        },
        fromDateDisp() {
            this.toMin = moment().format(this.details.selectedFromDate)
            return this.details.selectedFromDate;
        },
        toDateDisp() {
            this.fromMax = moment().format(this.details.selectedToDate)
            return this.details.selectedToDate;
        },
    },
    details: {
        handler(value) {
            this.itemDetails = value
        },
        deep: true,
    },
    data() {
        return {
            isShowDetails: this.isShow ?? false,
            itemDetails: this.details,
            fromDateMenu: false,
            fromDateMenu: false,
            fromDateVal: "",
            toDateMenu: false,
            toDateVal: "",
            minDate: "1997-01-01",
            maxDate: "",
            fromMax: "",
            toMin: "",
            merchantCode: "",
            listOfMerchantCodes: this.merchantCodes ?? [],
            rules: {
                requiredField: v => !!v || "Required field.",
            },
        }
    },
    mounted() {
        this.initializeForm();
    },
    methods: {
        initializeForm() {
            this.merchantCode = this.details.selectedMerchant ?? ""
            this.fromDateVal = this.details.selectedFromDate ?? ""
            this.toDateVal = this.details.selectedToDate ?? ""
        },
        close() {
            this.isShowDetails = false
            this.$emit('close-dialog', 'details')
        },
        forApprovalStatus() {
            if (this.$refs.formEntry.validate()) {
                this.$emit("for-approval-status", this.populatedForm);
                this.$refs.formCard.$el.scrollIntoView({
                behavior: "smooth",
                });
            }
        },
        commarized(value) {
            var numeral = require("numeral");
            return numeral(value).format("0,0[.]00");
        },
    }
}
</script>
